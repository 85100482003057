@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
	height: 100%;
}

/** TODO: Review all of these styles, I don't think they're necessary */
.h-88 {
	height: 26rem;
}
.h-110 {
	height: 30rem;
}
.h-100 {
	height: 26rem;
}
.h-myScreen {
	height: 62vh;
}

.h-myEditScreen {
	height: 72vh;
}

.w-110 {
	width: 27rem;
}

.w-210 {
	width: 38rem;
}
.w-splitThirds {
	width: 30%;
}
.w-splitHalves {
	width: 47%;
}
.hideThat {
	overflow: hidden;
}
.ant-radius {
	border-radius: 2px;
}
.h-splitThirds {
	height: 30%;
}
/** END REVIEW */

/** overwrite antd global background color */
.app-layout {
	background: #f9f9f9 !important;
}
.site-layout {
	background: #f5f5f7 !important;
	padding: 140px 0px;
	min-height: 100vh !important;
}

/** start - navbar menu item styles */
.header-menu-item {
	color: #bdbdbd !important;
	transition-property: background-color, color, fill;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 300ms;
}
.header-menu-item:hover {
	color: #ffffff;
}
.header-menu-item-select,
.header-menu-item:hover {
	color: #ffffff !important;
}
/** end - navbar menu item styles */

/** mobile navbar */
.mobile-header {
	z-index: 1;
	overflow: hidden;
	position: fixed;
	top: 0px;
	padding-top: 45px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

/** layout content wrapper for mobile app (authenticated mobile) */
.mobile-layout-content {
	padding: 15px 10px 90px 10px;
	margin-top: 90px;
	position: fixed;
	z-index: 0;
	background: #f9f9f9;
}

/** mobile landscape */
@media screen and (max-height: 420px) {
	.mobile-header {
		padding-top: 10px;
	}
	.mobile-layout-content {
		padding: 15px 40px;
		margin-top: 50px;
	}
}

/** layout content wrapper for entire app (authenticated desktop) */
.app-layout-content {
	padding: 45px 30px;
	min-height: 100vh;
	background: #f9f9f9;
}

/** layout content wrapper for safe content (unauthenticated) */
.site-layout-content {
	max-width: 1450px;
	margin: 0px auto;
	padding: 0px 30px;
	overflow: hidden;
}

/** narrow layout content wrapper for safe content (unauthenticated) */
.site-layout-content-narrow {
	max-width: 1000px;
	margin: 0px auto;
	padding: 0px 30px;
	overflow: hidden;
}

/** mobile landscape */
@media screen and (max-width: 420px) {
	.site-layout-content {
		max-width: 1450px;
		margin: 0px auto;
		padding: 0px 15px;
		overflow: hidden;
	}
}

/** fade styles for landing page */
.fade-in-section {
	opacity: 0;
	transform: translateY(0vh);
	visibility: hidden;
	transition: opacity 0.5s ease-out, transform 1s ease-out;
	will-change: opacity, visibility;
}
.fade-in-section.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
}

/** need to align all icons in the app */
svg {
	vertical-align: baseline;
}

/* STRIPE BG */
.angle-block {
	width: 100%;
	height: 800px;
	transform: skewY(-9deg);
	transform-origin: 0;
	position: absolute;
	top: 0;
}

.bg-ant-lightgray {
	background-color: #fafafa;
}
